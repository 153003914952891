import { cloneElement, useLayoutEffect } from "react";
import { useGetContext } from "@onefront/react-sdk";
import { useMatch, useOutletContext } from "react-router-dom";

const DefaultContent = ({ tabName }) => {
  const { setDocumentsToolbar } = useOutletContext();

  useLayoutEffect(() => {
    setDocumentsToolbar(null);
  }, []);

  return tabName;
};

export const Tab = ({ basePath }) => {
  const {
    params: { tabName }
  } = useMatch(`/${basePath}/:tabName`);

  const tabElement = useGetContext(
    `documents.tabs.${tabName}`,
    <DefaultContent />
  );

  return cloneElement(tabElement, { tabName });
};
