import { loadable } from "../../utils/loadable";

const NumberSeriesList = loadable(() =>
  import(/* webpackChunkName: "NumberSeriesList" */ "./NumberSeriesList")
);

export const numberSeriesView = [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/number-series",
      element: <NumberSeriesList />
    }
  }
];
