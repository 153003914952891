export const onInitFeature = {
  target: "$INIT_FEATURE",
  handler: (_, { setContext, createExtension }) => {
    // Move config into context, possibly with validation
    // setContext("documents.tabNames", getConfig("documents.tabs"));
    // setContext("documents.basePath", getConfig("documents.basePath"));

    createExtension
      .sync("$DOCUMENTS_POPULATE_TAB")
      .map(($) => $[0])
      // TODO: validate each object
      .forEach(({ tab, element }) =>
        setContext(`documents.tabs.${tab}`, element)
      );
  }
};
