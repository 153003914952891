import { loadable } from "../../utils/loadable";

const FeatureView = loadable(() =>
  import(
    /* webpackChunkName: "Views__NumberSeriesManagement" */ "./FeatureView"
  )
);

export const numberSeriesManagement = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/number-series/create",
      element: <FeatureView name="NumberSeriesCreate" />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/number-series/:numberSeriesId/edit",
      element: <FeatureView name="NumberSeriesEdit" />
    }
  }
];
