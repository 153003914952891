import { loadable } from "../../utils/loadable";

const FeatureView = loadable(() =>
  import(/* webpackChunkName: "Views__InvoicesView" */ "./FeatureView")
);

export const invoicesView = [
  {
    target: "$DOCUMENTS_POPULATE_TAB",
    handler: {
      tab: "invoices",
      element: <FeatureView name="InvoicesListPage" />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/documents/invoices/:invoiceId/quick-view",
      element: <FeatureView name="InvoiceQuickViewPage" />
    }
  }
];
