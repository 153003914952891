import { Route } from "react-router-dom";

import { loadable } from "../../utils/loadable";

const FeatureView = loadable(() =>
  import(/* webpackChunkName: "Views__InvoiceManagement" */ "./FeatureView")
);

const tabs = [
  <Route
    key={"products-and-services"}
    path="products-and-services"
    element={<FeatureView name="TabProductsAndServices" />}
  />,
  <Route
    path="details"
    element={<FeatureView name="TabDetails" />}
    key={"details"}
  />,
  <Route
    key={"payments-and-summary"}
    path="payments-and-summary"
    element={<FeatureView name="TabPaymentsAndTotals" />}
  />,
  <Route
    key={"notes-and-attachments"}
    path="notes-and-attachments"
    element={<FeatureView name="TabNotesAndAttachments" />}
  />,
  <Route
    key={"document-references"}
    path="document-references"
    element={<FeatureView name="TabDocumentReferences" />}
  />
];

export const invoiceManagement = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/documents/invoices/:invoiceId/edit",
      element: <FeatureView name="InvoiceEditPage" />,
      children: tabs
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/documents/invoices/create",
      element: <FeatureView name="InvoiceCreate" />,
      children: tabs
    }
  }
];
