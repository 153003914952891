import { onInitFeature } from "./on-init-feature";
import { onOneLayoutRoute } from "./on-one-layout-route";

export const documentsView = ({ registerTargets }) => {
  registerTargets({
    DOCUMENTS_POPULATE_TAB: "documents/populate/tab"
  });

  return [onInitFeature, onOneLayoutRoute];
};
