import { useAuth, useAxios, useGetContext } from "@onefront/react-sdk";

export class GraphqlError extends Error {
  constructor(message, response) {
    super(message);
    this.name = "GraphqlError";
    this.originalError = response.data.errors[0];
    this.response = response;
  }
}

export class GraphqlAccessDeniedError extends GraphqlError {
  constructor(message, response) {
    super(message, response);
    this.name = "GraphqlAccessDeniedError";
  }
}

export const useGraphql = () => {
  const { axios, buildConfig } = useAxios();
  const { token } = useAuth();

  const accessTokenHeaderKey = useGetContext("one.rest.header.accessToken.key");
  const accessTokenHeaderValue = useGetContext(
    "one.rest.header.accessToken.value"
  );

  const fetch = async (receivedConfig) => {
    const config = buildConfig(receivedConfig, {
      headers: {
        ...receivedConfig?.headers,
        [accessTokenHeaderKey]: accessTokenHeaderValue.replace("{TOKEN}", token)
      }
    });

    try {
      const res1 = await axios.request(config);
      if (res1.data.errors && res1.data.errors.length) {
        const [error] = res1.data.errors;

        // Detect an AccessError
        // (will trigger implicit retry)
        if (error.extensions.code === "access-denied") {
          throw new GraphqlAccessDeniedError(error.message, res1);
        }

        // Generic GraphQL Error
        throw new GraphqlError(error.message, res1);
      }

      return res1;
    } catch (err1) {
      // Authorization error:
      if (!(err1 instanceof GraphqlAccessDeniedError)) {
        throw err1;
      }

      // Real error:
      console.log("@fetch::authErro -> retry");
      throw new Error("Auth retry not yet implemented");
    }
  };

  const query = (query, variables, headers = null) =>
    fetch({
      method: "post",
      url: "hasuraApi://v1/graphql",
      data: { query, variables },
      headers
    }).then((res) => res.data);

  const mutation = (query, variables, headers = null) =>
    fetch({
      method: "post",
      url: "hasuraApi://v1/graphql",
      data: { query, variables },
      headers
    }).then((res) => res.data);

  return {
    query,
    mutation
  };
};
