import { CqrsProvider } from "./state/CqrsProvider";

export { useCqrs } from "./state/use-cqrs";
export { optimisticUpdate } from "./utils/optimistic-update";

export const cqrs = () => {
  return [
    {
      target: "$REACT_ROOT_COMPONENT",
      handler: (props) => ({
        component: CqrsProvider,
        props
      })
    }
  ];
};
