import { Route, Navigate } from "react-router-dom";
import { loadable } from "../../utils/loadable";

import { Tab } from "./components/Tab";
const DocumentsRoot = loadable(() =>
  import(/* webpackChunkName: "DocumentsRoot" */ "./components/DocumentsRoot")
);

export const onOneLayoutRoute = {
  target: "$ONE_LAYOUT_ROUTE",
  handler: (_, { getConfig }) => {
    const tabs = getConfig("documents.tabs");
    const basePath = getConfig("documents.basePath");

    return {
      exact: true,
      path: basePath,
      element: <DocumentsRoot tabs={tabs} />,
      children: [
        <Route key="index" index element={<Navigate to={tabs[0]} />} />,
        ...tabs.map((tab) => (
          <Route key={tab} path={tab} element={<Tab basePath={basePath} />} />
        ))
      ]
    };
  }
};
