import pkg from "../package.json";

/**
 * OneFront SDK
 * ============
 *
 * This package provides most of the Services that you may need
 * in a OnePlatform Application.
 *
 * You write simple React Components and interact with the SDK
 * using modern hooks API.
 *
 * The SDK guides you into dividing you App into isolated Features
 * that you will glue together using ForrestJS APIs.
 *
 * REACT-STARTED
 * Swap comment on line 19/20 to use React Starter
 *
 * NOTE: You must create a company for your TSID:
 * https://appdev.teamsystemdigital.com/
 * (it takes up to 20 minutes to work... keep reloading...)
 */
//import onefront from "@onefront/react-sdk";
import onefront from "@drift/oneplatfront"; // will become @onefront/react-starter

/**
 * Import the App's Features
 * =========================
 *
 * A Feature is an isolated portion of business logic that
 * interacts with the App's Services
 */

import { cqrs } from "./services/cqrs";
import { documentsView } from "./features/documents-view";
import { dummyDashboard } from "./features/dummy-dashboard";
import { invoicesView } from "./features/invoices-view";
import { invoiceManagement } from "./features/invoice-management";
import { numberSeriesView } from "./features/number-series-view";
import { numberSeriesManagement } from "./features/number-series-management";

// @TODO: evaluate wether to apply envalid or similar validation
//        or no default in production and hard crash
const APP_ID = process.env.REACT_APP_APP_ID || "demo";
const PROXY_URL =
  process.env.REACT_APP_PROXY_BASE_URL || "http://localhost:4010";
console.log(`AppId: ${APP_ID}; Version: ${pkg.version};`);

/**
 * Configure and Run the App
 * =========================
 *
 * The boot process returns a Promise that you can handle
 * to figure out whether all was good or not.
 */
onefront
  .run({
    // trace: "compact",
    settings: {
      one: {
        auth: {
          token: {
            refresh:
              (process.env.REACT_APP_TOKEN_FORCE_REFRESH || "yes") === "yes"
          }
        },
        login: {
          target: {
            url: "loginApp",
            params: ["mode=redirect", `appId=${APP_ID}`]
          }
        },
        box: {
          app: { id: "dcbe1292-007e-49c1-8e42-1c4fed662f15" },
          module: { name: "scm::invoicing::app", version: pkg.version }
        }
      },
      proxy: {
        url: PROXY_URL
      },
      pdf: {
        urn: "scm::invoices::backend-jobs-manager::api"
      },
      products: {
        urn: "scm::trade-items::app"
      },
      numberSeries: {
        urn: APP_ID
      },
      documents: {
        basePath: "documents",
        tabs: ["quotes", "invoices", "credit-notes", "payments", "accounting"],
        defaultTab: "Invoices" // TODO: open this as default if not url is correctly set
      },
      constants: {
        headers: {
          workspaceId: "x-workspace-id"
        }
      }
      //   ns: getConfig("one.i18n.options.ns", ["react-sdk", "app"]),
      // defaultNS: getConfig("one.i18n.options.defaultNS", "app"),
    },
    services: [cqrs],
    features: [
      documentsView,
      dummyDashboard,
      invoicesView,
      invoiceManagement,
      numberSeriesView,
      numberSeriesManagement,
      // FIX: set default (static) language to english
      // TODO: fixme
      {
        target: "$ONE_I18N_OVERRIDE_CONFIG",
        handler: (cfg) => ({
          ...cfg,
          load: "languageOnly",
          ns: ["react-sdk", "scm"]
        })
      }
    ]
  })
  .catch(console.error);
