import { lazy, Suspense } from "react";

const applySuspense = (Component) => (props) =>
  (
    <Suspense fallback={"loading..."}>
      <Component {...props} />
    </Suspense>
  );

export const loadable = (module) => applySuspense(lazy(module));
